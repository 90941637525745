import React, { useState } from 'react';
import { Box, Button, TextField, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { createSolicitation } from '../services/createSolicService'; // Importa a função de solicitação

const CreateSolic: React.FC = () => {
  const [symbol, setSymbol] = useState('');
  const [forceBuy, setForceBuy] = useState(false);
  const [buyRecorrent, setBuyRecorrent] = useState(false); // Novo campo buyRecorrent
  const [buyQty, setBuyQty] = useState<number | ''>(''); // Ajuste para número ou string
  const [profit, setProfit] = useState<number | ''>(''); // Ajuste para número ou string
  const [stopLess, setStopLess] = useState<number | ''>(''); // Ajuste para número ou string
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      // Converte valores vazios para 0 antes de passar para a função
      await createSolicitation(
        symbol,
        forceBuy,
        buyQty === '' ? 0 : buyQty, // Converte para 0 se vazio
        profit === '' ? 0 : profit,   // Converte para 0 se vazio
        stopLess  === '' ? 0 : stopLess,
        buyRecorrent 
      );
      setSuccess('Solicitação criada com sucesso!');
      setSymbol('');
      setForceBuy(false);
      setBuyRecorrent(false); // Reseta o campo após envio
      setBuyQty('');
      setProfit('');
      setStopLess('');
    } catch (err) {
      setError('Erro ao criar solicitação.');
    }
  };

  // Função para formatar o valor
  const formatValue = (value: string) => {
    // Remove todas as vírgulas
    const newValue = value.replace(/,/g, '.');
    // Retorna o valor convertido para number, ou '' se não for um número válido
    return isNaN(Number(newValue)) ? '' : Number(newValue);
  };

  return (
    <Box sx={{ maxWidth: '400px', margin: '0 auto', mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Criar Solicitação
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {success && <Typography color="success.main">{success}</Typography>}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Símbolo"
          fullWidth
          margin="normal"
          value={symbol}
          onChange={(e) => setSymbol(e.target.value)}
          required
        />
        
        <TextField
          label="Quantidade de Compra"
          type="number" // Mantém como number
          fullWidth
          margin="normal"
          value={buyQty}
          onChange={(e) => setBuyQty(formatValue(e.target.value))}
          inputProps={{ step: "0.00001", min: 0 }} // Define passos e valor mínimo
          placeholder="Ex: 100.00" // Placeholder para instruir o usuário
          required
        />
        
        <TextField
          label="Lucro"
          type="number" // Mantém como number
          fullWidth
          margin="normal"
          value={profit}
          onChange={(e) => setProfit(formatValue(e.target.value))}
          inputProps={{ step: "0.00001", min: 0 }} // Define passos e valor mínimo
          placeholder="Ex: 1.01 para lucro de 10%" // Placeholder para instruir o usuário
        />
        
        <TextField
          label="Stop Loss"
          type="number" // Mantém como number
          fullWidth
          margin="normal"
          value={stopLess}
          onChange={(e) => setStopLess(formatValue(e.target.value))}
          inputProps={{ step: "0.00001", min: 0 }} // Define passos e valor mínimo
          placeholder="Ex: 1.05 para prejuizo de 5%" // Placeholder para instruir o usuário
        />
        
        <FormControlLabel
          control={
            <Checkbox
              checked={forceBuy}
              onChange={(e) => setForceBuy(e.target.checked)}
            />
          }
          label="Forçar Compra"
        />
        
        <FormControlLabel
          control={
            <Checkbox
              checked={buyRecorrent}
              onChange={(e) => setBuyRecorrent(e.target.checked)}
            />
          }
          label="Compra Recorrente" // Novo rótulo para o campo booleano buyRecorrent
        />

        <Button variant="contained" color="primary" type="submit" fullWidth>
          Criar
        </Button>
      </form>
    </Box>
  );
};

export default CreateSolic;
