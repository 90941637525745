import axiosInstance from '../axiosConfig'; // Certifique-se de que o caminho esteja correto

// Função para buscar os dados de posições abertas
export const fetchPosicaoAberto = async () => {
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const response = await axiosInstance.get(`/posicaoaberto`, { headers });
  return response.data;
};

// Função para fechar uma posição com base no solicId
export const fecharPosicao = async (solicId: string) => {
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  await axiosInstance.put(`/forcesell/${solicId}`, {}, { headers });
};

// Adiciona esta linha para transformar o arquivo em um módulo
export {};
