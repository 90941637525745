import axiosInstance from "../axiosConfig";
import axios from "../axiosConfig";
import { AuthRequest, AuthResponse } from "../types/Login";

async function login(params: AuthRequest): Promise<AuthResponse> {
  const response = await axiosInstance.post("/session", params );
  return response.data;
}

async function checkUserExists(email: string) {
  try {
    const response = await axiosInstance.post(`/checkmail`, {
      mail: email, // Passando o email no corpo da requisição
    });
    return response.data; // Deve retornar true se o usuário existir
  } catch (error) {
    console.error('Erro ao verificar usuário:', error);
    return false;
  }
}



export default { login, checkUserExists };




