import axios from "axios";
import { useEffect, useState } from "react";
import axiosInstance from "../axiosConfig";


interface Fill {
  id: string;
  price: number;
  qty: number;
  commission: number;
  commissionAsset: string;
  traderId: string;
}

interface TradeRecord {
  id: string;
  symbol: string;
  created_at: string;
  orderId: number;
  price: number;
  origQty: number;
  executedQty: number;
  cummulativeQuoteQty: number;
  status: string;
  type: string;
  side: string;
  workingTime: string;
  solicId: string;
  fills: Fill[];
  total: number;
}

interface PosicaoData {
  buyRecords: TradeRecord[];
  sellRecords: TradeRecord[];
  division: number;
  buySum: number;
  sellSum: number;
  groupedRecords?: Record<string, TradeRecord[]>;
}

export const usePosicaoData = () => {
  const [posicaoData, setPosicaoData] = useState<PosicaoData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPosicaoData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axiosInstance.get(`/posicaoall`, { headers });

        const combinedRecords = [
          ...response.data.buyRecords.map((record: TradeRecord) => ({
            ...record,
            type: "BUY",
          })),
          ...response.data.sellRecords.map((record: TradeRecord) => ({
            ...record,
            type: "SELL",
          })),
        ].sort((a: TradeRecord, b: TradeRecord) =>
          a.solicId.localeCompare(b.solicId)
        );

        const groupedRecords: Record<string, TradeRecord[]> = combinedRecords.reduce(
          (acc, record) => {
            if (!acc[record.solicId]) {
              acc[record.solicId] = [];
            }
            acc[record.solicId].push(record);
            return acc;
          },
          {} as Record<string, TradeRecord[]>
        );

        setPosicaoData({
          ...response.data,
          groupedRecords,
        });
      } catch (error: any) {
        console.error("Failed to fetch position data:", error);
        setError("Falha ao carregar os dados de posição.");
      } finally {
        setLoading(false);
      }
    };

    fetchPosicaoData();
  }, []);

  return { posicaoData, loading, error };
};
