// posicaoAllPeriodo.ts
import axios from 'axios';
import axiosInstance from '../axiosConfig';

// PosicaoAllPeriodo.ts
export interface BuyResumo {
    totalAmount: number;
    totalCommission: number;
    totalQty: number;
    price: number;
    totalDifference: number; // Adicionado
    percentualGain: number; // Adicionado
}

export interface SellResumo {
    totalAmount: number;
    totalCommission: number;
    totalQty: number;
    price: number;
    totalDifference: number; // Adicionado
    percentualGain: number; // Adicionado
}


export interface Posicao {
    solicId: string;
    symbol: string;
    buyResumo: BuyResumo;
    sellResumo: SellResumo;
    totalDifference: number;
    percentualGain: number;
}

export interface SumarioPorData {
    totalBuyAmount: number;
    totalSellAmount: number;
    percentualGain: number;
    totalDifference: number; // Adicionado
}

export interface PosicaoResponse {
    groupedByDate: Record<string, Posicao[]>;
    sumarioPorData: Record<string, SumarioPorData>;
}

// Função para buscar dados do servidor
export const obterPosicoesNoPeriodo = async (startDate: string, endDate: string): Promise<PosicaoResponse> => {
    try {
        // Obtendo o token do localStorage
        const token = localStorage.getItem('token'); // Substitua 'token' pelo nome correto, se necessário

        const response = await axiosInstance.post<PosicaoResponse>('/posicaoallperiodo', {
            startDate,
            endDate,
        }, {
            headers: {
                Authorization: `Bearer ${token}`, // Adicionando o token no cabeçalho
            },
        });
        
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar posições:", error);
        throw error;
    }
};
