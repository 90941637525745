import axiosInstance from "../axiosConfig";

export const createSolicitation = async (
  symbol: string,
  forceBuy: boolean,
  buyQty: number,
  profit: number,
  stopLess: number,
  buyRecorrent : boolean
) => {
  // Recupera o token do localStorage
  const token = localStorage.getItem('token');

  // Configura os cabeçalhos da requisição
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // Converte booleano para numérico (0 ou 1)
  const forceBuyNumeric = forceBuy ? 1 : 0;
  //const buyRecorrentNumeric = buyRecorrent ? 1 : 0;

  // Faz a requisição ao backend
  await axiosInstance.post('/solic', {
    symbol,
    forceBuy: forceBuyNumeric, // Envia o valor convertido
    buyQty,                    // Adiciona a quantidade de compra
    profit,                    // Adiciona o lucro
    stopLess,                   // Adiciona o stop loss
    buyRecorrent
  }, { headers });
};
