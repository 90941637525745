import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  TextField,
  Button,
  Grid,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Snackbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom"; // Importando o useNavigate
import axiosInstance from "./axiosConfig"; // Importando a configuração do Axios

// Definindo a interface dos dados do usuário e suas configurações
interface UserConfig {
  id: string;
  exchange: number;
  apiKey: string;
  secretKey: string;
  apiUrl: string;
  profit: number;
  buyQty: number;
  stopLess: number;
  interval: string;
  period: string;
  telegramBotToken: string;
  telegramChatId: string;
  userId: string;
}

interface User {
  id: string;
  username: string;
  name: string;
  mail: string;
  userConfigs: UserConfig[];
}

const UserDetails: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const navigate = useNavigate(); // Usando o useNavigate para redirecionamento

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          setError("Usuário não autenticado.");
          setLoading(false);
          return;
        }

        const response = await axiosInstance.get("/me", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUser(response.data);
        setLoading(false);
      } catch (error: any) {
        console.error("Failed to fetch user data:", error);
        setError("Falha ao carregar os dados do usuário.");
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: keyof User
  ) => {
    if (user) {
      setUser({ ...user, [key]: e.target.value });
    }
  };

  const handleConfigChange = (
    e: SelectChangeEvent<number> | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: string,
    key: keyof UserConfig
  ) => {
    if (user) {
      const value = e.target.value;

      const updatedConfigs = user.userConfigs.map((config) =>
        config.id === id ? { ...config, [key]: value as any } : config
      );

      setUser({ ...user, userConfigs: updatedConfigs });
    }
  };

  const handleSave = async () => {
    if (!user) {
      setError("Usuário não encontrado.");
      return;
    }

    try {
      const token = localStorage.getItem("token");

      if (!token) {
        setError("Usuário não autenticado.");
        return;
      }

      // Fazendo uma única chamada PUT para atualizar o usuário e as configurações
      await axiosInstance.put(`/usersconfigs/${user.id}`, user, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setSuccessMessage("Atualizado com sucesso!"); // Setando a mensagem de sucesso
      setIsEditing(false);

      // Redirecionar para o dashboard após um curto intervalo
      setTimeout(() => {
        navigate("/dashboard"); // Redirecionar para a página do dashboard
      }, 2000); // Tempo de espera em milissegundos
    } catch (error: any) {
      console.error("Failed to save user data:", error);
      setError("Falha ao salvar os dados do usuário.");
    }
  };

  if (loading) {
    return (
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container component="main" maxWidth="md">
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="md">
      <Typography component="h1" variant="h5" sx={{ mb: 4 }}>
        Detalhes do Usuário
      </Typography>
      {user && (
        <>
          <Typography variant="h6">Informações Básicas:</Typography>
          {["name", "username", "mail"].map((field) => (
            <TextField
              key={field}
              label={field.charAt(0).toUpperCase() + field.slice(1)}
              value={user[field as keyof User]}
              onChange={(e) => handleInputChange(e, field as keyof User)}
              fullWidth
              disabled={!isEditing}
              sx={{ mb: 2 }}
            />
          ))}

          <Typography variant="h6" sx={{ mt: 4 }}>
            Configurações do Usuário:
          </Typography>
          {user.userConfigs.map((config) => (
            <Paper key={config.id} sx={{ p: 2, mb: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth disabled={!isEditing}>
                    <InputLabel>Exchange</InputLabel>
                    <Select
                      value={config.exchange}
                      onChange={(e) => handleConfigChange(e, config.id, "exchange")}
                    >
                      <MenuItem value={1}>Binance</MenuItem>
                      {/* Adicione outras exchanges conforme necessário */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="API Key"
                    value={config.apiKey}
                    onChange={(e) => handleConfigChange(e, config.id, "apiKey")}
                    fullWidth
                    disabled={!isEditing}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Secret Key"
                    value={config.secretKey}
                    onChange={(e) => handleConfigChange(e, config.id, "secretKey")}
                    fullWidth
                    disabled={!isEditing}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="API URL"
                    value={config.apiUrl}
                    onChange={(e) => handleConfigChange(e, config.id, "apiUrl")}
                    fullWidth
                    disabled={!isEditing}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Profit (%)"
                    value={config.profit}
                    onChange={(e) => handleConfigChange(e, config.id, "profit")}
                    fullWidth
                    disabled={!isEditing}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Quantidade de Compra"
                    value={config.buyQty}
                    onChange={(e) => handleConfigChange(e, config.id, "buyQty")}
                    fullWidth
                    disabled={!isEditing}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Stop Loss (%)"
                    value={config.stopLess}
                    onChange={(e) => handleConfigChange(e, config.id, "stopLess")}
                    fullWidth
                    disabled={!isEditing}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Intervalo"
                    value={config.interval}
                    onChange={(e) => handleConfigChange(e, config.id, "interval")}
                    fullWidth
                    disabled={!isEditing}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Período"
                    value={config.period}
                    onChange={(e) => handleConfigChange(e, config.id, "period")}
                    fullWidth
                    disabled={!isEditing}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Telegram Bot Token"
                    value={config.telegramBotToken}
                    onChange={(e) => handleConfigChange(e, config.id, "telegramBotToken")}
                    fullWidth
                    disabled={!isEditing}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Telegram Chat ID"
                    value={config.telegramChatId}
                    onChange={(e) => handleConfigChange(e, config.id, "telegramChatId")}
                    fullWidth
                    disabled={!isEditing}
                  />
                </Grid>
              </Grid>
            </Paper>
          ))}

          {isEditing ? (
            <Button variant="contained" onClick={handleSave} sx={{ mt: 3 }}>
              Salvar
            </Button>
          ) : (
            <Button variant="contained" onClick={() => setIsEditing(true)} sx={{ mt: 3 }}>
              Editar
            </Button>
          )}

          {/* Snackbar para mostrar a mensagem de sucesso */}
          <Snackbar
            open={!!successMessage}
            autoHideDuration={6000}
            onClose={() => setSuccessMessage(null)}
            message={successMessage}
          />
        </>
      )}
    </Container>
  );
};

export default UserDetails;
