import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Container,
  Typography,
  Avatar,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';
import LoginService from './services/Login';
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { useAuth } from './AuthContext';

interface DecodedToken {
  email: string;
  name: string;
  picture: string;
  sub: string; // Adicionei o googleId aqui
}

function Login() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await LoginService.login({
        mail: email,
        password: password,
      });

      const token = response.message.token;
      if (token) {
        login(token); 
        navigate("/dashboard");
      } else {
        throw new Error('Token não recebido');
      }
    } catch (error: any) {
      console.error("Login failed:", error);
      setError("Login falhou. Por favor cheque suas credenciais!");
    }
  };

  const onSuccess = async (res: CredentialResponse) => {
    console.log("Login Successful: ", res);
    const token = res.credential;
    if (token) {
     
     
      const decodedToken = jwtDecode<DecodedToken>(token);
      console.log("Token decodificado: ", decodedToken); // Verifique se o googleId está aqui
      console.log ("lucas")

     
let googleId = decodedToken.sub
        // Aqui, verifique se o googleId está presente
        console.log("Google ID (sub): ", decodedToken.sub);


  
      // Verifique se o email está cadastrado no sistema
      const userExists = await LoginService.checkUserExists(decodedToken.email);
      if (userExists) {
        login(token);
        navigate("/dashboard");
      } else {
        // Redirecionar para a tela de registro com email e googleId
        console.log ("mostrar o googleId")
        console.log (googleId)
        navigate("/register", { state: { email: decodedToken.email, googleId: googleId } });
      }
    } else {
      setError("Token não recebido do Google.");
    }
  };

  const onError = () => {
    console.error("Login Failed");
    setError("Login falhou com Google. Por favor, tente novamente!");
  };

  return (
    <GoogleOAuthProvider clientId="471996525470-lscbtnu9gdtcpt2iedlrumib01rtkr8b.apps.googleusercontent.com">
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>
          {error && <Typography color="error">{error}</Typography>}
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>

            <GoogleLogin
              onSuccess={onSuccess}
              onError={onError}
            />
          </Box>
          <Typography variant="body2">
            Don't have an account? <Link to="/register">Register here</Link>
          </Typography>
        </Box>
      </Container>
    </GoogleOAuthProvider>
  );
}

export default Login;
