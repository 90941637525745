// PosicaoAll.tsx
import React from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { usePosicaoData } from "../services/posicaoAllServices";

const PosicaoAll: React.FC = () => {
  const { posicaoData, loading, error } = usePosicaoData();

  if (loading) {
    return (
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container component="main" maxWidth="md">
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="lg">
      <Typography component="h1" variant="h5" sx={{ mb: 4 }}>
        Posição de Compras e Vendas
      </Typography>

      {posicaoData && (
        <>
          {/* Resumo */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6">Resumo:</Typography>
            <Typography>Somatório de Compras: {posicaoData.buySum.toFixed(2)}</Typography>
            <Typography>Somatório de Vendas: {posicaoData.sellSum.toFixed(2)}</Typography>
            <Typography>Divisão: {posicaoData.division.toFixed(4)}</Typography>
          </Box>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Símbolo</TableCell>
                  <TableCell>Quantidade</TableCell>
                  <TableCell>Preço Total</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Data</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {posicaoData.groupedRecords &&
                  Object.entries(posicaoData.groupedRecords).map(
                    ([solicId, records]) => {
                      const buyRecords = records.filter(record => record.type === "BUY");
                      const sellRecords = records.filter(record => record.type === "SELL");

                      const totalBuy = buyRecords.reduce((sum, record) => sum + record.total, 0);
                      const totalSell = sellRecords.reduce((sum, record) => sum + record.total, 0);
                      const priceDifference = totalSell - totalBuy;
                      const ratio = totalBuy > 0 ? totalSell / totalBuy : 0;

                      return (
                        <React.Fragment key={solicId}>
                          <TableRow>
                            <TableCell colSpan={7} sx={{ fontWeight: "bold" }}>
                              Solicitação ID: {solicId}
                            </TableCell>
                          </TableRow>
                          {records.map((record) => (
                            <TableRow key={record.id}>
                              <TableCell>{record.symbol}</TableCell>
                              <TableCell>{record.executedQty}</TableCell>
                              <TableCell>{record.total.toFixed(2)}</TableCell>
                              <TableCell>{record.status}</TableCell>
                              <TableCell>{record.type}</TableCell>
                              <TableCell>{new Date(record.created_at).toLocaleString()}</TableCell>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell colSpan={7} sx={{ fontWeight: "bold", textAlign: "right" }}>
                              Preço Total (Sell - Buy): {priceDifference.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={7} sx={{ fontWeight: "bold", textAlign: "right" }}>
                              Razão (Sell / Buy): {ratio.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    }
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Container>
  );
};

export default PosicaoAll;
