// src/Home.tsx
import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
} from "@mui/material";
import Login from "./Login"; // ajuste o caminho conforme necessário

const Home: React.FC = () => {
  const [openLogin, setOpenLogin] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpenLogin(true);
  };

  const handleClose = () => {
    setOpenLogin(false);
  };

  return (
    <div>
      <h1>Bem-vindo à Home</h1>
      <Button variant="contained" onClick={handleClickOpen}>
        Sign In
      </Button>

      <Dialog open={openLogin} onClose={handleClose} fullWidth>
        <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ width: '100%', maxWidth: '400px', overflow: 'hidden' }}>
            <Login />
          </Box>
        </DialogContent>
        
      </Dialog>
    </div>
  );
};

export default Home;
