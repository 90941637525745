import React, { useEffect, useState } from 'react';
import { Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress } from '@mui/material';
import { obterPosicoesComprar, PosicaoComprar } from '../services/posicoesComprar';
import axios from 'axios';
import axiosInstance from '../axiosConfig';

const PosicoesComprar: React.FC = () => {
  const [posicoes, setPosicoes] = useState<PosicaoComprar[]>([]);
  const [erro, setErro] = useState<string | null>(null);
  const [carregando, setCarregando] = useState<boolean>(true); // Estado de carregamento

  useEffect(() => {
    const fetchPosicoesComprar = async () => {
      setCarregando(true); // Inicia o carregamento
      try {
        const resultado = await obterPosicoesComprar();
        setPosicoes(resultado);
      } catch (error) {
        setErro('Erro ao carregar as posições à comprar');
      } finally {
        setCarregando(false); // Termina o carregamento
      }
    };

    fetchPosicoesComprar();
  }, []);

  const handleComprarAgora = async (solicId: string) => {
    const token = localStorage.getItem('token'); // Supondo que o token JWT está salvo no localStorage
    try {
     const response =  await axiosInstance.put(
        `/forcebuy/${solicId}`, 
        {}, 
        {
          headers: {
            Authorization: `Bearer ${token}`, // Autenticação com token JWT
          }
        }
      );

     
      alert(response.data.message);
    } catch (error) {
      alert('Erro ao realizar a compra.');
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" align="center" gutterBottom>
          Posições à Comprar
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {carregando ? ( // Exibe o CircularProgress enquanto carrega
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : erro ? (
          <Typography variant="body1" color="error">
            {erro}
          </Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Id</strong></TableCell>
                  <TableCell><strong>Symbol</strong></TableCell>
                  <TableCell><strong>Price</strong></TableCell>
                  <TableCell><strong>Ações</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {posicoes.length > 0 ? (
                  posicoes.map((posicao) => (
                    <TableRow key={posicao.symbol}>
                      <TableCell>{posicao.solicId}</TableCell>
                      <TableCell>{posicao.symbol}</TableCell>
                      <TableCell>{posicao.price.toFixed(6)}</TableCell>
                      <TableCell>
                        <Button 
                          variant="contained" 
                          color="primary" 
                          onClick={() => handleComprarAgora(posicao.solicId)}
                        >
                          Comprar Agora
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      Nenhuma posição disponível
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};

export default PosicoesComprar;
