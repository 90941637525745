import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box, Button, TextField, CircularProgress } from '@mui/material';
import axiosInstance from './axiosConfig';
import BinanceChart from './BinanceChart';

interface CryptoData {
  symbol: string;
  ema3: number;
  ema20: number;
  rsi: number;
}

const Listapairsrsi: React.FC = () => {
  const [cryptoData, setCryptoData] = useState<CryptoData[]>([]);
  const [interval, setInterval] = useState<string>('5m'); // Conteúdo padrão
  const [periodoRsi, setPeriodoRsi] = useState<string>('14'); // Conteúdo padrão
  const [selectedSymbol, setSelectedSymbol] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Para controlar o loading

  const fetchData = async () => {
    try {
      setLoading(true); // Inicia o loading
      const response = await axiosInstance.post(`/pairsrsi/`, {
        interval: interval,
        periodoRsi: periodoRsi,
      });
      setCryptoData(response.data.symbolsWithRSI);
    } catch (error) {
      console.error('Erro ao buscar dados', error);
    } finally {
      setLoading(false); // Finaliza o loading
    }
  };

  const handleShowChart = (symbol: string) => {
    if (selectedSymbol === symbol) {
      setSelectedSymbol(null);
    } else {
      setSelectedSymbol(symbol);
    }
  };

  return (
    <Box>
      <Typography variant="h4" align="center" gutterBottom>
        Lista de Pares com RSI
      </Typography>

      {/* Campos de entrada */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
        <TextField
          label="Interval"
          variant="outlined"
          value={interval}
          onChange={(e) => setInterval(e.target.value)}
          sx={{ marginRight: 2 }}
        />
        <TextField
          label="Período RSI"
          variant="outlined"
          value={periodoRsi}
          onChange={(e) => setPeriodoRsi(e.target.value)}
          sx={{ marginRight: 2 }}
        />
        <Button variant="contained" color="primary" onClick={fetchData}>
          Buscar
        </Button>
      </Box>

      {/* Exibir loading enquanto os dados estão sendo carregados */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>
          {cryptoData.map((crypto) => (
            <Card key={crypto.symbol} sx={{ width: 300, margin: 2 }}>
              <CardContent>
                <Typography variant="h6">{crypto.symbol}</Typography>
                <Typography>EMA 3: {crypto.ema3.toFixed(2)}</Typography>
                <Typography>EMA 20: {crypto.ema20.toFixed(2)}</Typography>
                <Typography>RSI: {crypto.rsi.toFixed(2)}</Typography>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleShowChart(crypto.symbol)}
                  sx={{ marginTop: 2 }}
                >
                  {selectedSymbol === crypto.symbol ? 'Esconder Gráfico' : 'Mostrar Gráfico'}
                </Button>

                {selectedSymbol === crypto.symbol && (
                  <Box sx={{ marginTop: 2 }}>
                    <BinanceChart symbol={crypto.symbol} />
                  </Box>
                )}
              </CardContent>
            </Card>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Listapairsrsi;
