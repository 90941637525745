import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataResponse } from '../types/dataTypes';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, CircularProgress, Box } from '@mui/material';
import axiosInstance from '../axiosConfig';
import { fecharPosicao, fetchPosicaoAberto } from '../api/api';

const PosicaoAberto: React.FC = () => {
    const [data, setData] = useState<DataResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [loadingAction, setLoadingAction] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);

            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('Token não encontrado');
                }

                const response = await axiosInstance.get<DataResponse>('/posicaoaberto', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                setData(response.data);
            } catch (err: any) {
                setError(err.response?.data?.message || 'Erro ao buscar os dados. Verifique sua conexão ou token.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleSolicAction = async (solicId: string) => {
        setLoadingAction(true);
        try {
            await fecharPosicao(solicId);
            alert(`Solicitação ${solicId} processada com sucesso.`);
            const updatedData = await fetchPosicaoAberto();
            setData(updatedData);
        } catch (err: any) {
            alert(`Erro ao processar solicitação ${solicId}: ${err.message}`);
        } finally {
            setLoadingAction(false);
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!data || data.length === 0) {
        return (
            <Typography variant="h6" align="center">
                Nenhum registro encontrado.
            </Typography>
        );
    }

    return (
        <div>
            {data.map((dailyData) => (
                <div key={dailyData.date}>
                    <Typography variant="h6" gutterBottom>
                        Data: {dailyData.date}
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Solic ID</TableCell>
                                    <TableCell>Symbol</TableCell>
                                    <TableCell>Preço Atual</TableCell>
                                    <TableCell>Preço de Compra</TableCell>
                                    <TableCell>Quantidade Executada</TableCell>
                                    <TableCell>Valor Acumulado</TableCell>
                                    
                                    <TableCell>Lucro/Prejuízo Atual</TableCell>
                                    <TableCell>Ação</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dailyData.entries.map((entry) => (
                                    <TableRow key={entry.solicId}>
                                        <TableCell>{entry.solicId}</TableCell>
                                        <TableCell>{entry.symbol}</TableCell>
                                        <TableCell>{entry.price.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 8 })}</TableCell>
                                        <TableCell>{entry.fillsPrice.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 8 })}</TableCell>
                                        <TableCell>{entry.executeQty.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 8 })}</TableCell>
                                        <TableCell>{entry.cummulativeQuoteQty.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 8 })}</TableCell>
                                        <TableCell>{entry.lucroPrejuizoAtual.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 8 })}</TableCell>
                                        <TableCell>
                                            <Button 
                                                variant="contained" 
                                                color="primary" 
                                                onClick={() => handleSolicAction(entry.solicId)}
                                                disabled={loadingAction} 
                                            >
                                                {loadingAction ? <CircularProgress size={24} /> : 'Vender Agora'}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            ))}
        </div>
    );
};

export default PosicaoAberto;
