import React, { useState } from 'react';
import { Grid, TextField, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Box } from '@mui/material';
import { obterPosicoesNoPeriodo, PosicaoResponse } from '../services/posicaoAllPeriodo';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, Filler);

const PosicaoAllPeriodo: React.FC = () => {
    const [startDate, setStartDate] = useState<string>(
        new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]
    );
    const [endDate, setEndDate] = useState<string>(new Date().toISOString().split('T')[0]);
    const [dados, setDados] = useState<PosicaoResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(false); // Estado de carregamento

    const calcularPosicoes = async () => {
        setLoading(true); // Ativa o carregamento
        try {
            const resultados = await obterPosicoesNoPeriodo(startDate, endDate);
            setDados(resultados);
        } catch (error) {
            console.error("Erro ao calcular posições:", error);
        } finally {
            setLoading(false); // Desativa o carregamento
        }
    };

    const prepareChartData = () => {
        if (!dados) return { labels: [], datasets: [] };

        const labels = Object.keys(dados.sumarioPorData);
        const totalBuyAmount = labels.map(date => dados.sumarioPorData[date].totalBuyAmount);
        const totalSellAmount = labels.map(date => dados.sumarioPorData[date].totalSellAmount);
        const percentualGain = labels.map(date => dados.sumarioPorData[date].percentualGain);

        let ganhoAcumulado = 0;
        const ganhoAcumuladoData = labels.map((date) => {
            const totalDifferenceForDay = Number(dados.sumarioPorData[date].totalDifference);
            ganhoAcumulado += totalDifferenceForDay; 
            return ganhoAcumulado;
        });

        return {
            labels,
            datasets: [
                {
                    label: 'Total de Compras',
                    data: totalBuyAmount,
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    fill: true,
                },
                {
                    label: 'Total de Vendas',
                    data: totalSellAmount,
                    borderColor: 'rgba(255, 99, 132, 1)',
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    fill: true,
                },
                {
                    label: 'Percentual de Ganho',
                    data: percentualGain,
                    borderColor: 'rgba(255, 206, 86, 1)',
                    backgroundColor: 'rgba(255, 206, 86, 0.2)',
                    fill: true,
                },
                {
                    label: 'Ganho Acumulado',
                    data: ganhoAcumuladoData,
                    borderColor: 'rgba(54, 162, 235, 1)',
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    fill: true,
                },
            ],
        };
    };

    const calcularResumoDiario = () => {
        if (!dados) return [];

        const resumoDiario = Object.entries(dados.sumarioPorData).map(([date, resumo]) => ({
            date,
            totalDifference: Number(resumo.totalDifference),
        }));

        return resumoDiario;
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">Calcular Posições no Período</Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    type="date"
                    label="Data Início"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    type="date"
                    label="Data Final"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" onClick={calcularPosicoes}>
                    Calcular
                </Button>
            </Grid>
            <Grid item xs={12}>
                {loading ? ( // Exibe o CircularProgress enquanto os dados estão sendo carregados
                    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                        <CircularProgress />
                    </Box>
                ) : dados ? (
                    <>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Solic ID</TableCell>
                                        <TableCell>Symbol</TableCell>
                                        <TableCell>Tipo</TableCell>
                                        <TableCell>Total Qty</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Total Amount</TableCell>
                                        <TableCell>Total Commission</TableCell>
                                        <TableCell>Lucro</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(dados.groupedByDate).map(([date, posicoes]) => {
                                        let totalDifferencePerDay = 0;
                                        return (
                                            <React.Fragment key={date}>
                                                <TableRow>
                                                    <TableCell colSpan={8}>
                                                        <Typography variant="h6">{date}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                {posicoes.map((item) => {
                                                    const buyTotal = item.buyResumo.totalAmount + item.buyResumo.totalCommission;
                                                    const sellTotal = item.sellResumo.totalAmount - item.sellResumo.totalCommission;
                                                    const lucro = sellTotal - buyTotal;
                                                    totalDifferencePerDay += lucro;

                                                    return (
                                                        <React.Fragment key={item.solicId}>
                                                            <TableRow>
                                                                <TableCell>{item.solicId}</TableCell>
                                                                <TableCell>{item.symbol}</TableCell>
                                                                <TableCell>Buy</TableCell>
                                                                <TableCell>{item.buyResumo.totalQty.toFixed(5)}</TableCell>
                                                                <TableCell>{item.buyResumo.price.toFixed(8)}</TableCell>
                                                                <TableCell>{item.buyResumo.totalAmount.toFixed(2)}</TableCell>
                                                                <TableCell>{item.buyResumo.totalCommission.toFixed(6)}</TableCell>
                                                                <TableCell>-</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>{item.solicId}</TableCell>
                                                                <TableCell>{item.symbol}</TableCell>
                                                                <TableCell>Sell</TableCell>
                                                                <TableCell>{item.sellResumo.totalQty.toFixed(5)}</TableCell>
                                                                <TableCell>{item.sellResumo.price.toFixed(8)}</TableCell>
                                                                <TableCell>{item.sellResumo.totalAmount.toFixed(2)}</TableCell>
                                                                <TableCell>{item.sellResumo.totalCommission.toFixed(6)}</TableCell>
                                                                <TableCell>{lucro.toFixed(6)}</TableCell>
                                                            </TableRow>
                                                        </React.Fragment>
                                                    );
                                                })}
                                                <TableRow>
                                                    <TableCell colSpan={4} align="right"><strong>Total do Dia</strong></TableCell>
                                                    <TableCell colSpan={4}><strong>{totalDifferencePerDay.toFixed(6)}</strong></TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div style={{ marginTop: '20px' }}>
                            <Typography variant="h5">Gráfico de Totais por Data</Typography>
                            <Line data={prepareChartData()} options={{ responsive: true }} />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Typography variant="h5">Resumo Diário do Total Difference</Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Data</TableCell>
                                            <TableCell>Total Difference</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {calcularResumoDiario().map((resumo) => (
                                            <TableRow key={resumo.date}>
                                                <TableCell>{resumo.date}</TableCell>
                                                <TableCell>{resumo.totalDifference.toFixed(6)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </>
                ) : (
                    <Typography variant="body1">Nenhum dado disponível.</Typography>
                )}
            </Grid>
        </Grid>
    );
};

export default PosicaoAllPeriodo;
