// services/posicoesComprar.ts
import axiosInstance from '../axiosConfig';

export interface PosicaoComprar {
  solicId: string;
  symbol: string;
  price: number;
}

export const obterPosicoesComprar = async (): Promise<PosicaoComprar[]> => {
  try {
    // Obtenha o token de autenticação (presumindo que esteja armazenado no localStorage)
    const token = localStorage.getItem('token');

    // Faz a requisição com o token de autorização no cabeçalho
    const response = await axiosInstance.get<PosicaoComprar[]>('posicaoacomprar', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao obter posições a comprar:', error);
    throw error;
  }
};
