import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'; // Importar componentes do Chart.js
import axios from 'axios';

// Registrar escalas e plugins
ChartJS.register(
  CategoryScale,    // Escala de categorias no eixo x
  LinearScale,      // Escala linear no eixo y
  PointElement,     // Elemento dos pontos
  LineElement,      // Elemento das linhas
  Title,            // Título
  Tooltip,          // Tooltip (dica flutuante)
  Legend            // Legenda
);

interface BinanceChartProps {
  symbol: string;
}

const BinanceChart: React.FC<BinanceChartProps> = ({ symbol }) => {
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const response = await axios.get('https://api.binance.com/api/v3/klines', {
          params: {
            symbol,
            interval: '5m', // Intervalo desejado (ex: 1h, 1d)
          },
        });
        const data = response.data;

        const timestamps = data.map((entry: any) => new Date(entry[0]).toLocaleTimeString());
        const prices = data.map((entry: any) => entry[4]);

        const formattedData = {
          labels: timestamps,
          datasets: [
            {
              label: `${symbol} Price`,
              data: prices,
              fill: false,
              backgroundColor: 'rgba(75,192,192,0.4)',
              borderColor: 'rgba(75,192,192,1)',
            },
          ],
        };

        setChartData(formattedData);
      } catch (error) {
        console.error('Error fetching chart data', error);
      }
    };

    fetchChartData();
  }, [symbol]);

  return chartData ? <Line data={chartData} /> : <p>Loading chart...</p>;
};

export default BinanceChart;
